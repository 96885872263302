import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo";
import AboutImage from "../components/about_image";
import ServicesImage from "../components/service_image";
import HealthImage from "../components/health_image";
import ContactImage from "../components/contact_image";

const HealthSafety = () => (
    <Layout>
        <SEO title="Health and Safety" description="We clean silos to the following standards - IOSH/SPA Food Safety Passport, City & Guilds Confined Space Medium Risk, Working at Heights, Safe Use of Harnesses, First Aid, Mobile Access Platforms, Mobile boom lift platform"/>
            <div className={'max-w-screen-lg mx-auto'}>
                    <Logo />
            </div>
            <div className={'bg-white p-4 max-w-screen-lg mx-auto'}>
                    <h1 className={'text-2xl text-indigo-700'}>Health & Safety</h1>
                    <p className={'my-2'}>Due to the nature of the work carried out, health and safety is of paramount importance. All our personnel have many years' experience and are highly trained in all aspects of the work we carry out. All our work is fully covered by Employers and Public liability insurance. Both our lifting equipment and electrical items are tested and inspected on a 6 monthly basis by an independent external company. A 'Permit to Work' is to be issued before any confined space is entered and a risk assessment and method statement to be provided before commencement of any work.</p>
                    <p className={'my-2'}>
                        <strong>Members of our team are holders of:</strong>
                    </p>
                    <ul className={'list-disc ml-6'}>
                            <li>IOSH/SPA Food Safety Passport</li>
                            <li>City &amp; Guilds Confined Space Medium Risk</li>
                            <li>Working at Heights</li>
                            <li>Safe Use of Harnesses</li>
                            <li>First Aid</li>
                            <li>Mobile Access Platforms</li>
                            <li>Mobile boom lift platform</li>
                    </ul>
                    <p className={'my-2'}><strong>All our operatives are fully trained and conversant in:</strong></p>
                    <ul className={'list-disc ml-6'}>
                            <li>Confined space entry procedures</li>
                            <li>Permit to work procedures</li>
                            <li>Safe use of equipment</li>
                            <li>Gas monitoring equipment</li>
                            <li>Escape procedures</li>
                            <li>Emergency rescue procedures and first aid</li>
                            <li>Insecticide spraying/fogging</li>
                    </ul>
            </div>
        <div className={'max-w-screen-lg mx-auto'}>
            <div className={'flex flex-wrap text-white mt-4'}>
                <div className={'flex-1 pb-2 my-2 mr-2 bg-yellow-500'}>
                    <AboutImage />
                    <div className={'px-4'}>
                        <h2 className={'text-2xl font-normal mb-2'}>about us</h2>
                    </div>
                    <div className={'text-center'}>
                        <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/about-us">More Information</Link>
                    </div>
                </div>
                <div className={'flex-1 pb-2 m-2 bg-orange-500'}>
                    <ServicesImage />
                    <div className={'px-4'}>
                        <h2 className={'text-2xl font-normal mb-2'}>our services</h2>
                    </div>
                    <div className={'text-center'}>
                        <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/our-services">More Information</Link>
                    </div>
                </div>
                <div className={'flex-1 pb-2 m-2 bg-teal-700'}>
                    <HealthImage />
                    <div className={'px-4'}>
                        <h2 className={'text-2xl font-normal mb-2'}>health & safety</h2>
                    </div>
                    <div className={'text-center'}>
                        <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/health-and-safety">More Information</Link>
                    </div>
                </div>
                <div className={'flex-1 pb-2 m-2 ml-2 bg-indigo-700'}>
                    <ContactImage />
                    <div className={'px-4'}>
                        <h2 className={'text-2xl font-normal mb-2'}>contact us</h2>
                    </div>
                    <div className={'text-center'}>
                        <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/contact-us">More Information</Link>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default HealthSafety
